import { removeEmptyProps } from 'lib/helpers/removeEmptyProps'
import { IWidgetPayload, IWidgetSettingsPayload, IWidgetSettingsUpdatePayload } from 'lib/types/payloads/widget'
import { IWidget, IWidgetSettings } from 'lib/types/widget'

export function widgetSettingToPayloadAdapter (dto: IWidgetSettingsPayload): IWidgetSettingsUpdatePayload {
  const data = {
    platform: dto.platform,
    advertising_mode: dto.advertising_mode,
    advertising_frequency: dto.advertising_frequency,
    advertising_position: dto.advertising_position,
    leaderboard_position: dto.leaderboard_position,
    ignore_categories: dto.ignore_categories,
    box_size: dto.box_size,
  }
  removeEmptyProps(data)
  return data
}

export function payloadToWidgetSettingsAdapter (dto: IWidgetSettingsPayload): IWidgetSettings {
  return {
    url: dto.url,
    obsDockSlug: dto.slug_obs_dock,
    slug: dto.slug,
    platform: dto.platform,
    boxSize: dto.box_size,
    referralPromotion: dto.referral_promotion,
    stream: {
      delay: dto.stream_delay,
      enabled: dto.stream_active,
    },
    advertising: {
      mode: dto.advertising_mode,
      frequency: dto.advertising_frequency,
      position: dto.advertising_position,
    },
    leaderboard: {
      position: dto.leaderboard_position,
    },
    adManualEnabled: dto.ad_manual_enable,
    ignoreCategories: dto.ignore_categories,
    enabled: dto.widget_active,
    botEnabled: dto.bot_active,
    extensionEnabled: dto.extension_active,
    nightbot: {
      twitch: {
        connected: dto.nightbot_twitch_connected,
        moderator: dto.nightbot_twitch_moderator,
      },
      youtube: {
        connected: dto.nightbot_youtube_connected,
        moderator: dto.nightbot_youtube_moderator,
      },
      trovo: {
        connected: dto.nightbot_trovo_connected,
        moderator: dto.nightbot_trovo_moderator,
      },
    },
    relogin: dto.relogin,
    bsRequired: dto.bs_required,
    allowAdultContent: dto.allow_adult_content,
  }
}

export function payloadToWidgetAdapter (dto: IWidgetPayload): IWidget {
  return {
    ...payloadToWidgetSettingsAdapter(dto),
    streamer: dto.streamer,
    twitch: dto.twitch,
    youtube: dto.youtube,
    trovo: dto.trovo,
  }
}
