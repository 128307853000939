/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube-icon': {
    width: 18,
    height: 14,
    viewBox: '0 0 18 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.026 1.038c.777.214 1.391.828 1.596 1.605C18 4.055 18 7 18 7s0 2.946-.368 4.357a2.26 2.26 0 01-1.596 1.606C14.625 13.34 9 13.34 9 13.34s-5.625 0-7.026-.378a2.278 2.278 0 01-1.596-1.606C0 9.946 0 7 0 7s0-2.945.368-4.357a2.26 2.26 0 011.596-1.605C3.375.659 9 .659 9 .659s5.625 0 7.026.379zM11.864 7L7.159 9.68V4.32L11.864 7z" _fill="#666"/>'
  }
})
