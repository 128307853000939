import { LocaleDictionary } from 'locales/locale-dictionary'

const dictionary: LocaleDictionary = {
  placeholder: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    repeatPassword: 'Confirmar contraseña',
    phoneNumber: 'Teléfono',
    translationLanguage: 'Idioma de transmisión',
    country: 'País',
    city: 'Ciudad',
    enterCity: 'Ingrese el nombre de la ciudad',
    state: 'Estado',
    enterState: 'Ingrese el nombre del estado',
    zip: 'Código postal',
    address: 'Dirección',
    enterAddress: 'Ingrese la dirección',
    gender: 'Género',
    birthYear: 'Año de nacimiento',
    birthday: 'Fecha de nacimiento',
    fullname: 'Nombre completo',
    name: 'Nombre de pila',
    enterName: 'Ingrese el nomb',
    lastname: 'Apellido',
    enterLastname: 'Ingrese el apellido',
    middlename: 'Segundo nombre',
    enterMiddlename: 'Ingrese el segundo nombre',
    companyName: 'Nombre de la empresa',
    contactPerson: 'Nombre completo',
    commonCurrency: 'Moneda común',
    campaign: {
      type: 'Tipo de campaña',
      name: 'Nombre de la campaña',
    },
    inn: 'Número de Identificación Personal',
    iban: 'IBAN',
    bic: 'Código BIC / SWIFT del banco',
    paypalEmail: 'Ingrese el correo electrónico vinculado a PayPal',
    selectDate: 'Seleccionar fecha',
    chooseCategories: 'Elegir categorías',
    optional: 'opcional',
    passportNumber: 'Número de pasaporte',
    panCard: 'Tarjeta PAN',
    panCardIfUpload: 'Si carga la tarjeta PAN, deducimos el 10% de TDS, de lo contrario el 20% de TDS',
    panCardNumber: 'Número de tarjeta PAN',
    enterPassportNumber: 'Ingrese PN',
    enterPanCardNumber: 'Ingrese PAN',
    payoutMode: 'Modo de pago',
    choosePayoutMode: 'Elija el modo de pago',
    accountVpa: 'Cuenta VPA',
    bankIFSC: 'Código IFSC del banco',
    beneficiaryAccountNumber: 'Número de cuenta del beneficiario',
    partnerSearch: 'Buscar por ID o nombre',
    choose: 'Seleccionar',
    excludeSelected: 'Excluir seleccionado',
    dragAFile: 'Arrastrar archivo',
    dragImageHint: 'JPG o PNG, tamaño {size} MB o menos',
  },
  partnerSearchFilter: {
    add: 'Agregar filtro',
    status: 'Estado',
    platform: 'Plataforma',
    advertiser: 'Anunciante',
    allAdvertisers: 'Todos los anunciantes',
  },
  button: {
    signIn: 'Iniciar sesión',
    save: 'Guardar',
    saving: 'Guardando ..',
    saved: 'Guardado',
    send: {
      static: 'Enviar',
      pending: 'Enviando ..',
      success: 'Enviado',
    },
    requestHasBeenSent: 'La solicitud ha sido enviada',
    recoverySend: 'Enviar enlace de recuperación',
    recoverySent: 'Enlace enviado',
    passwordChanged: 'Contraseña cambiada',
    runAd: 'Ejecutar campaña',
    toLink: 'Enlace',
    remove: 'Eliminar',
    delete: {
      static: 'Eliminar',
      process: 'Eliminando',
      success: 'Eliminado',
      successFeminine: 'Eliminada',
      successMasculine: 'Eliminado',
      successPlural: 'Eliminados',
    },
    create: {
      campaigns: 'Crear campaña',
      groups: 'Crear grupo',
      creatives: 'Crear creativo',
    },
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    sendPreview: {
      static: 'Enviar vista previa',
      success: 'Vista previa enviada',
    },
    chooseFile: {
      static: 'Elegir un archivo',
    },
    back: {
      static: 'Atrás',
    },
    attach: {
      static: 'Vincular',
    },
    join: {
      static: 'Unirse',
    },
    cancel: {
      static: 'Cancelar',
    },
    next: {
      static: 'Siguiente',
    },
    resetLink: 'Restablecer enlace',
    resetQR: 'Restablecer código QR',
    showQR: 'Mostrar código QR',
    hideQR: 'Ocultar código QR',
    showManager: 'Abrir Mobile Manager',
    copyLink: {
      static: 'Copiar enlace',
      success: 'Enlace copiado',
    },
    copy: {
      static: 'Copiar',
      success: 'Copiado',
    },
    saveChanges: {
      static: 'Guardar cambios',
      pending: 'Guardando ..',
      success: 'Cambios guardados',
    },
    getStarted: 'Empezar',
    copyFromExisting: 'Copiar de existente',
    loadMore: 'Carga más',
  },
  validator: {
    required: 'Campo requerido',
    invalidEmail: 'Formato de correo electrónico incorrecto',
    invalidPhoneNumber: 'Formato de número de teléfono incorrecto',
    birthYearLess: 'El año debe ser menor que',
    birthYearMore: 'El año debe ser mayor que',
    shortPassword: 'La contraseña debe tener más de 8 caracteres',
    matchPassword: 'Las contraseñas no coinciden',
    positiveNum: 'Solo número positivo',
    alphanumeric: 'Solo se permiten letras y números',
    inn: 'Incorrecto Inn',
    bic: 'Incorrecto Bik',
    numbersAndSpaces: 'Formato incorrecto',
    userInnNotFound: 'Autónomo con este TIN no encontrado',
    bankAccount: 'Ingrese 20 dígitos sin espacios',
    onlyCyrillic: 'Ingrese el valor en cir',
    urlIsInvalid: 'La URL no es válida',
    embed: 'Proporcionar un enlace de incrustación válido',
    youtubeUrl: 'Enlace no válido de YouTube',
    uniqueYoutubeUrl: 'Ya agregaste video con esta identificación',
  },
  helpers: {
    perHour: 'Cada vez 1 hora',
    perMin: 'Cada vez {minutes} min',
    seconds: '{n} segundos | {n} segundo | {n} segundos',
    streamers: '{n} creadores | {n} creador | {n} creadores',
    inSeconds: 'en {n} segundos | en {n} segundo | en {n} segundos',
    someOfSome: '{0} de {1}',
    timeAgo: {
      mins: 'hace {n} minutos | hace {n} minuto | hace {n} minutos',
      minutes: 'hace {n} minutos | hace {n} minuto | hace {n} minutos',
      hours: 'hace {n} horas | hace {n} hora | hace {n} horas',
      days: 'hace {n} días | hace {n} día | hace {n} días',
      months: '{n} meses atrás | {n} mes atrás | {n} meses atrás',
      years: '{n} años atrás | {n} año atrás | {n} años atrás',
    },
    timeLeft: {
      mins: '{n} minutos restantes | {n} minuto restante | {n} minutos restantes',
      minutes: '{n} minutos restantes | {n} minuto restante | {n} minutos restantes',
      hours: '{n} horas restantes | {n} hora restante | {n} horas restantes',
      days: '{n} días restantes | {n} día restante | {n} días restantes',
      months: '{n} meses restantes | {n} mes restante | {n} meses restantes',
      years: '{n} años restantes | {n} año restante | {n} años restantes',
    },
  },
  pagination: {
    next: 'Siguiente',
    prev: 'Atrás',
  },
  sidebar: {
    navigation: {
      streamer: {
        home: {
          title: 'Inicio',
        },
        dashboard: {
          title: 'Dashboard',
        },
        freemium: {
          title: 'Uplify Link',
        },
        campaigns: {
          title: 'Campañas',
          subitems: {
            'campaigns-livestream': 'Livestream',
            'campaigns-preroll': 'Preroll',
          },
        },
        wallet: {
          title: 'Billetera',
        },
        widget: {
          title: 'Configuración',
        },
        referrals: {
          title: 'Referencias',
        },
        extension: {
          title: 'Extensión',
        },
        debug: {
          title: 'Debug',
        },
      },
      partner: {
        campaigns: {
          title: 'Campañas',
          subitems: {
            'brand-awareness': 'Patrocinio',
            performance: 'Interactivo',
            preroll: 'Preroll',
          },
        },
        advertisers: {
          title: 'Anunciantes',
        },
        holdings: {
          title: 'Tenencias',
        },
        creators: {
          title: 'agencia',
        },
        debug: {
          title: 'Debug',
        },
      },
      profile: {
        settings: 'Configuración de la cuenta',
        logout: 'Cerrar sesión',
      },
    },
    links: {
      help: {
        label: 'Ayuda',
        url: 'https://help.uplify.app/',
      },
      news: {
        label: '¿Qué hay de nuevo?',
        url: '',
      },
      feedback: {
        label: 'Comentarios',
        url: 'https://discord.com/channels/465868803268542475/1118478966467080283',
      },
      discord: {
        label: 'Discord',
        url: 'https://discord.gg/uplify',
      },
    },
    requestDemo: 'Necesito ayuda',
    socials: {
      title: 'Síguenos',
    },
  },
  other: {
    adblock: {
      bold: 'Adblock está bloqueando la funcionalidad de la aplicación.',
      normal: 'Por favor, agregue Uplify a la lista blanca.',
    },
    advice: {
      attention: 'Atención',
      hint: 'Sugerencia',
    },
    uploader: {
      loading: 'Cargando',
      processing: 'Procesando',
      declined: 'Rechazado',
      fileUploadError: 'Error al cargar el archivo',
    },
    twitchAlert: 'El sitio está experimentando trabajo técnico. Consulte <a href="https://discord.gg/uplify" target="_blank">Discord</a> para obtener más detalles.',
    noData: 'Sin datos',
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
    timeAgo: {
      timeAgoMins: 'hace {n} minutos',
      timeAgoHours: 'hace {n} horas',
      timeAgoDays: 'hace {n} días',
      timeAgoMonths: 'hace {n} meses',
      timeAgoYears: 'hace {n} año',
    },
  },
  phrases: {
    terms: 'Términos',
    privacy: 'Privacidad',
    help: 'Ayuda',
    loading: 'Cargando',
    from: 'De',
    to: 'A',
  },
  closeDialog: {
    heading: 'Datos no guardados',
    text: 'Si cierras la ventana, los datos previamente introducidos se eliminarán.',
    btnSave: 'Continuar editando',
    btnContinue: 'Salir sin guardar',
  },
  links: {
    discord: 'https://discord.gg/uplify',
    terms: 'https://www.uplify.app/legal/terms',
    termsLink: 'https://www.uplify.app/legal/terms',
    privacy: 'https://www.uplify.app/legal/privacy-policy',
    howIsItCalculated: 'https://fanatical-fly-511.notion.site/Do-que-dependem-os-rendimentos-no-site-809472ce98814503992fbda94dfa5cbf',
    howToIncreaseCtr: 'https://fanatical-fly-511.notion.site/Do-que-dependem-os-rendimentos-no-site-809472ce98814503992fbda94dfa5cbf',
    whenWillIReceiveMyPayouts: 'https://fanatical-fly-511.notion.site/Quando-receberei-meu-pagamento-f1af908537cc4a86b6f029915e91c8a4',
    learnMoreAboutComissions: 'https://fanatical-fly-511.notion.site/M-todos-e-taxas-de-pagamento-f82c614cfce549f8b91eab83537c94d3',
    whyDoWeNeedThisData: 'https://help.uplify.app/en/articles/5640886-what-payout-methods-are-supported#h_6423c40103',
    widgetSetup: 'https://fanatical-fly-511.notion.site/Configurando-o-Widget-4e2cd74cbce74748bcf580bebd7f8348',
    chatbotSetup: 'https://fanatical-fly-511.notion.site/Cria-o-de-um-chatbot-fdcbaaba819947d78d94cdf8ab39fb26',
    chatbotSurvey: 'https://forms.gle/BUeq3tuEZGf6zH1b6',
    inStreamAdsFormat: 'https://fanatical-fly-511.notion.site/Formatos-publicit-rios-8b8a5c98102142bc9e2d949984e96da7',
    whatIsTier: 'https://fanatical-fly-511.notion.site/Do-que-dependem-os-rendimentos-no-site-809472ce98814503992fbda94dfa5cbf',
    whatsNew: 'https://www.uplify.app/blog-category/updates',
    whyAreWeCollectingThisData: 'https://help.uplify.app/en/articles/6175695-application-permissions',
    twitchExtension: 'https://dashboard.twitch.tv/extensions/wc13k0wnf9sk4omgod6p1r3os9kulv',
    takeSurveyLink: 'https://docs.google.com/forms/d/e/1FAIpQLScC_xZYqI-nB5bsUKqToKxpDsa7z6FKU2sTrlO_AfVd-mY8aQ/viewform?usp=sf_link',
  },
  demoInfo: {
    title: 'Estás en una cuenta de demostración',
    text: 'Algunas de las características no funcionan y requieren registro',
    twitchBtn: 'Regístrate con Twitch',
    youtubeBtn: 'Regístrate con YouTube',
    trovoBtn: 'Regístrate con Trovo',
  },
  progress: {
    of: 'de',
    forecast: 'Pronóstico',
  },
  errors: {
    unknown: 'Error desconocido',
    wrongExtension: 'Extensión de archivo incorrecta',
    fileSize: 'El archivo <b>{name}</b> ({size}MB) debe tener menos de {maxSize}MB',
  },
  campaignRow: {
    dailyActions: 'Acciones diarias',
    dailyActionsLimit: 'Límite de acciones diarias',
    dailyImpressions: 'Vistas diarias',
    dailyLimit: 'Límite diario',
    potentialIncome: 'Ingresos potenciales',
    yourIncome: 'Tus ingresos',
    yourCTR: 'Tu CTR',
    averageCTR: 'CTR Promedio',
    dateStart: 'Fecha de inicio',
    dateEnd: 'Fecha final',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    attended: 'Atendido',
    toggling: 'Pendiente..',
    tags: {
      undefinedFormat: 'Formato desconocido',
      dailyLimitDepleted: 'El límite de hoy se agotó',
      totalLimitDepleted: 'El límite de hoy se agotó',
      setupExtension: 'Configurar extensión',
      checkExtension: 'Verificar el estado de la extensión',
      extension: 'Extensión',
      actionBonus: 'Bono de acción',
      dailyActionsLimitDepleted: 'Límite de acción alcanzado',
      performanceDailyDepleted: 'El límite de hoy se agotó',
    },
    disabledUntil: 'Deshabilitado hasta: {date}',
    reasons: {
      lowCTR: '🔥 Razón: CTR bajo',
      lowCTRLink: {
        text: '¿Cómo mejorar mi CTR?',
        href: 'https://help.uplify.app/en/articles/5640847-uplify-basics',
      },
      moderator: 'Decisión del moderador',
    },
    format: {
      fullscreen: 'Overlay 50%',
      pip: 'Overlay 15%',
    },
    totalImpressions: 'Vistas disponibles',
  },
  campaignSidebar: {
    potentialRevenue: 'Ingresos potenciales',
    totalRevenue: 'Tus ingresos totales',
    revenue: 'Sus ingresos',
    estimatedRevenue: 'Ingresos estimados',
    ctr: 'Su CTR promedio',
    clicks: 'Clics',
    date: 'Fecha final',
    dateStart: 'Fecha de inicio',
    dateEnd: 'Fecha final',
    paymentType: 'Tipo de pago',
    pricePerViews: 'Precio por 1000 vistas',
    pricePerAction: 'Precio por 1 acción',
    frequency: 'Frecuencia',
    adFormat: 'Formato',
    advertiserCategory: 'Categoría de anunciantes',
    description: 'Descripción de la campaña',
    creativePreview: 'Vista previa creativa',
    messageInDescription: 'Mensaje en la descripción',
    chatMessage: 'Mensaje en chatbot',
    campaignOn: 'Campaña activada',
    campaignOff: 'Campaña desactivada',
    productLink: 'Enlace de producto',
    copyProductLink: 'Copiar enlace de producto personal',
    deliveredAtions: 'Acciones entregadas',
    deliveredImpressions: 'Vistas entregadas',
    deliveredDailyActions: 'Acciones entregadas',
    downloadCreative: 'Descargar creativa',
    copyDescription: 'Descripción de la copia',
    videos: 'Enlace de video',
    addNewVideo: 'Agregar otro video',
  },
  intercom: {
    freemium: '¡Hola! Quiero probar Uplify Link',
  },
  views: {
    auth: {
      streamerSignin: {
        title: '¡Hola! 👋',
        subtitle: 'Inicia sesión o regístrate en tus cuentas usando una de tus plataformas de streaming.',
        startWithTwitch: 'Continuar con Twitch',
        startWithYoutube: 'Continuar con YouTube',
        startWithTrovo: 'Continuar con Trovo',
        partnerLogin: 'Iniciar sesión para asociados',
        termsAndPolicy: 'Al iniciar sesión aceptas los <a href="@:links.terms" target="_blank">Términos</a> y la <a href="@:links.privacy" target="_blank">Política de privacidad</a>.',
        accountBlocked: 'La cuenta está bloqueada. Si esto es un error, por favor',
        twitchAuthError: 'Error de autorización de Twitch. Intenta nuevamente o',
        youtubeAuthError: 'Error de autorización de YouTube. Intenta nuevamente o',
        trovoAuthError: 'Error de autorización de Trovo. Inténtalo de nuevo o',
        discordAuthError: 'Error de autorización de Discord. Inténtalo de nuevo o',
        accountAlreadyExists: 'Estás intentando conectar una plataforma que está vinculada a un perfil diferente.',
        contactSupport: 'póngase en contacto con el soporte',
        info: {
          block1: {
            title: 'Uplify ayuda a monetizar el contenido de transmisión en vivo de una manera moderna.',
            linkText: 'Aprende más sobre nosotros',
            linkHref: 'https://www.uplify.app?utm_source=auth-page',
            videoHref: 'https://www.youtube.com/watch?v=llcLe5xusDg',
          },
          block2: {
            title: 'Contamos con la confianza de <strong>4,000 streamers</strong> y hemos pagado más de <strong>$400,000</strong>.',
            linkText: 'Únete a nuestra comunidad de Discord',
            linkHref: 'https://discord.gg/uplify',
          },
          block3: {
            title: '<strong>Formatos no alterados</strong> de marcas de renombre mundial.',
            linkText: 'Ver nuestros estudios de caso',
            linkHref: 'https://www.uplify.app/blog-category/cases?utm_source=auth-page',
          },
        },
      },
      streamerSettings: {
        hi: 'Hola',
        subtitle: 'Estamos casi ahí. Mientras tanto, conozcámonos mejor. Esto es necesario para el correcto funcionamiento de la plataforma.',
      },
      partnerSignin: {
        title: 'Iniciar sesión de socios',
        forgotPassword: '¿Olvidó su contraseña?',
        streamerLogin: 'Iniciar sesión de creador',
        wannaLaunchAd: '¿Quieres lanzar una campaña?',
        contactUs: 'Contáctanos para registrarte',
      },
      partnerRequest: {
        partnerSignUp: 'Regístrate como socio',
        partnerSignIn: 'Inicia sesión como socio',
        byStart: 'Al hacer clic en "Enviar", acepta los',
        terms: 'Términos',
        and: 'y',
        policy: 'Política de privacidad',
        successMsg: 'Hemos enviado instrucciones que describen acciones adicionales a su correo electrónico. Nuestro equipo se comunicará con usted en breve.',
      },
      resetPassword: {
        title: 'Restablecer contraseña',
        description: 'Ingresa el correo electrónico asociado a tu cuenta y te enviaremos un enlace para restablecer tu contraseña.',
        partnerSignIn: 'Inicia sesión como socio',
      },
      newPassword: {
        title: 'Crea una nueva contraseña',
        descriptionFirstRow: 'La contraseña no puede ser menor de 8 caracteres.',
        descriptionSecondRow: 'No use contraseñas de otros sitios web.',
        successText: 'Contraseña cambiada',
        openNow: 'Abrir ahora',
        pageOpenIn: 'La página de inicio de sesión se abrirá',
      },
      scopeTwitch: {
        heading: 'Antes de comenzar',
        headline: 'Para que el servicio Uplify funcione correctamente, necesitamos su permiso para acceder a las estadísticas de su canal y enviar mensajes de chat.',
        info: 'Respetamos su privacidad y tratamos sus datos con la máxima sensibilidad.',
        link: '¿Por qué estamos recolectando estos datos?',
        desciptions: [
          {
            title: 'Enviar mensajes de chat y salas en vivo',
            text: 'Esto nos permite enviar mensajes de chat con un enlace al sitio web del patrocinador.',
          },
          {
            title: 'Obtener su dirección de correo electrónico',
            text: 'Se solicita su correo electrónico a Uplify para confirmar su registro y enviar notificaciones importantes.',
          },
          {
            title: 'Ver los datos de moderación de su canal, incluidos los moderadores, las prohibiciones, los tiempos de espera y la configuración de Automod',
            text: 'Necesario para que el chatbot funcione correctamente y evite conflictos con otros chatbots en su canal.',
          },
          {
            title: 'Ver la configuración de transmisión de su canal',
            text: 'Acceso de solo lectura. Permite a Uplify optimizar la entrega de campañas patrocinadas a usted.',
          },
          {
            title: 'Ver mensajes de Chat en vivo y Salas',
            text: 'Nos permite leer los mensajes de chat del canal y realizar encuestas interactivas.',
          },
        ],
        redirectBtn: 'Ir a Twitch',
      },
      scopeYoutube: {
        heading: 'Antes de empezar',
        headline: 'Para que el servicio Uplify funcione correctamente&nbsp;necesitamos tu permiso para&nbsp;acceder a tus estadísticas de canal y enviar mensajes de chat.',
        info: 'Respetamos tu privacidad y tratamos tus datos con la máxima sensibilidad.',
        link: '¿Por qué estamos recopilando estos datos?',
        desciptions: [
          {
            title: 'Administra tu cuenta de YouTube',
            text: 'Nos permite obtener el número de espectadores activos en la transmisión en vivo, leer y enviar mensajes de chat con un enlace al sitio web del patrocinador.',
          },
        ],
        redirectBtn: 'Ir a YouTube',
      },
      scopeTrovo: {
        heading: 'Antes de comenzar',
        headline: 'Para que el servicio Uplify funcione correctamente, necesitamos tu permiso para acceder a tus estadísticas de canal y enviar mensajes de chat.',
        info: 'Respetamos tu privacidad y tratamos tus datos con la máxima sensibilidad.',
        link: '¿Por qué estamos recopilando estos datos?',
        desciptions: [
          {
            title: 'Ver su dirección de correo electrónico y otros perfiles de usuario',
            text: 'Se requiere su correo electrónico por parte de Uplify para confirmar su registro y enviar notificaciones importantes.',
          },
          {
            title: 'Ver los detalles de su canal',
            text: 'Acceso de solo lectura. Permite a Uplify optimizar la entrega de campañas patrocinadas para usted.',
          },
          {
            title: 'Enviar chats a mi canal',
            text: 'Esto nos permite enviar mensajes de chat con un enlace al sitio web del patrocinador.',
          },
        ],
        redirectBtn: 'Ir a Trovo',
      },
    },
    streamer: {
      home: {
        header: {
          greeting: 'Hola',
          introduction: 'Soy Constantine de Uplify. Te ayudaré a conocer mejor a tus espectadores y a comenzar a ganar con transmisiones en vivo.',
          letsDoIt: '¡Vamos a hacerlo!',
        },
        about: {
          label: '¿QUIÉNES SOMOS?',
          title: 'Uplify es una plataforma para trabajar con tus patrocinios directamente',
          description: 'Has llegado a la prueba beta abierta para creadores que hablan inglés. Conecta nuestro widget y pronto podrás comenzar a monetizar tus transmisiones.',
          howItWorks: 'Ver cómo funciona',
          setup: 'Configurar Uplify',
          statistic: {
            campaigns: {
              title: 'Completamos más de',
              amount: '50 campañas publicitarias',
            },
            payouts: {
              title: 'Pagamos más de',
              amount: '$400,000<br>para los creadores',
            },
          },
          howUplifyWorks: 'Cómo funciona Uplify',
        },
        next: {
          label: '¿QUÉ SIGUE?',
          description: 'Una vez que completes, comenzaremos a analizar tu audiencia y encontraremos los anunciantes adecuados en las próximas 2 semanas. ¡Hazte sentir como en casa!',
          steps: {
            first: 'Visite cualquier página y <a href="https://help.uplify.app" target="_blank">lea cómo funciona nuestro servicio →</a>',
            second: 'Únete a nuestra comunidad de creadores en <a href="https://discord.gg/uplify" target="_blank">Discord →</a>',
            third: 'Escribe a mí con cualquier pregunta a través del chat',
          },
        },
      },
      dashboard: {
        dashboard: 'Dashboard',
        greeting: 'Bienvenido de nuevo, {name} 👋',
        setup: {
          finishYourSetup: 'Finaliza tu configuración',
          stepsLeft: 'paso restante | pasos restantes',
          aboutXMin: 'Acerca de {min} min',
          steps: {
            fillYourProfile: 'Completa tu perfil',
            configureWidget: 'Configura el widget',
            connectChatbot: 'Conecta un chatbot',
            getFirstSponsorship: 'Obtén tu primera patrocinio',
            freemium: 'Gana más con tu comunidad',
          },
          guides: {
            fillYourProfile: 'https://www.youtube-nocookie.com/embed/ykLXIguDxeA?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            configureWidget: 'https://www.youtube-nocookie.com/embed/7yTaaRcvnNQ?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            connectChatbot: 'https://www.youtube-nocookie.com/embed/TKvlptfQ0ZE?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            getFirstSponsorship: 'https://help.uplify.app/en/articles/5640840-running-in-stream-campaigns',
            freemium: 'https://fanatical-fly-511.notion.site/Streamer-support-page-6c5d0c3b3e62448e94aa2ce1223e4986',
          },
          stepByStepGuide: 'Guía paso a paso',
          closeChecklist: 'Cerrar',
        },
        values: {
          estimatedEarnings: 'Ingresos estimados para&nbsp;{month}',
          turnOnUplifyPromo: 'Revisa tu billetera',
          earnedByReferalls: 'Ganado por referidos',
          getReferalLink: 'Obtén el enlace de referencia',
          newCampaignsComingSoon: 'Próximamente nuevas campañas',
          activeCampaignsToday: 'Campaña activa hoy | Campañas activas hoy',
          goToCampaigns: 'Ve a las campañas',
          earnedByFreemium: 'Ganado con Uplify Link',
          freemiumLink: 'Establece una meta',
        },
        resources: {
          discordText: 'Únete a nuestro servidor de Discord y comparte tu experiencia con la comunidad',
          blogText: 'Obtén las últimas actualizaciones y consejos de los streamers en nuestro blog',
          helpCenterText: 'Aprende todo sobre Uplify en nuestro Centro de Ayuda',
        },
        advice: {
          heading: '¿por qué importa?',
          title: 'Consigue desde $100 por mes',
          description: 'Para comenzar a monetizar tu canal y tener acceso a los mejores patrocinadores, necesitas completar ',
          steps: '{n} pasos | {n} paso | {n} pasos',
          descriptionEnd: '¡Vamos a hacerlo!',
        },
        levels: {
          heading: 'Tu crecimiento',
          goals: 'Objetivos de nivel {level}',
          perks: 'Ventajas del nivel {level}',
          lvl: 'LVL',
          days: 'días',
          discord: {
            active: 'Unido',
            notActive: 'Unirse',
          },
          twitch: {
            active: 'Activo',
            notActive: 'Configuración',
          },
          advice: {
            label: 'sobre niveles',
            title: 'Nivelarse para desbloquear más ventajas y funciones',
            description: 'El Programa de Lealtad de Uplify es una serie de objetivos incrementales que puedes esforzarte por lograr mientras transmitas en cualquiera de las plataformas admitidas. A medida que continúes creciendo y ganes nuevos niveles, habrá diferentes características y ventajas que se hagan disponibles para ti.',
            link: {
              text: 'Sobre niveles',
              url: 'https://help.uplify.app/en/articles/6563236-streamer-loyalty-program',
            },
          },
        },
      },
      campaigns: {
        header: {
          title: 'Campañas',
        },
        analytics: {
          returnBtn: 'Todas las campañas',
          reportBtn: {
            title: 'Informe de campaña',
            shortTitle: 'Informe',
            loading: 'Cargando',
            noData: 'Sin datos',
          },
          fields: {
            clicks: 'Hacer clics',
            ctr: 'CTR',
            impressions: 'Vistas',
            botClicks: 'Clics de fraude',
            date: 'Fecha',
          },
        },
        tabs: {
          current: 'Actual',
          completed: 'Completado',
        },
        platforms: {
          title: 'Su&nbsp;plataforma activa actual para la&nbsp;monetizaci&oacute;n',
          link: {
            text: 'Cambiar plataforma',
          },
        },
        freemium: {
          title: 'Más formas de ganar',
          msg: {
            title: 'SUPERCARGA TUS INGRESOS CON UPLIFY LINK',
            text: 'Descubra la&nbsp;clave para obtener ingresos recurrentes, participaci&oacute;n y&nbsp;relaciones significativas con los espectadores. Empieza a&nbsp;ganar hasta un&nbsp;70&nbsp;% m&aacute;s invitando a&nbsp;tus fans a&nbsp;que te&nbsp;apoyen ahora.',
            link: {
              text: 'Configurar Uplify.link',
            },
            getAccess: 'Obtener acceso',
          },
        },
        active: {
          title: 'Campañas activas',
          description: 'Sus campañas con estadísticas detalladas en tiempo real.',
        },
        inactive: {
          title: 'Campañas inactivas',
          description: 'De acuerdo con las categorías ignoradas o deshabilitadas manualmente.',
          link: '¿Cómo mejorar mi CTR?',
          linkHref: 'https://help.uplify.app/en/articles/5640847-uplify-basics',
        },
        future: {
          title: 'Próximas campañas',
          description: 'Campañas que estarán disponibles en un futuro próximo.',
        },
        notAvailable: {
          title: 'Campañas no disponibles',
          description: 'Campañas que no están disponibles para usted, o que están deshabilitadas por el moderador. Si se trata de un error, comuníquese con el soporte.',
        },
        advice: {
          title: 'Es importante saber',
          dailyLimit: {
            title: 'Límite diario',
            description: 'el número máximo de vistas disponibles para el usuario diariamente.',
          },
          dailyActionLimit: {
            title: 'Límite de acciones diarias',
            description: 'el número máximo de acciones disponibles para todos los usuarios diariamente. Tenga en cuenta que las acciones que excedan los límites diarios no serán pagadas.',
          },
          potentialIncome: {
            title: 'Ingresos potenciales',
            description: 'la cantidad potencial que se puede obtener de una campaña.',
            link: '¿Cómo se calcula?',
          },
          ctr: {
            title: 'CTR',
            description: 'es una medida de la clicabilidad de un anuncio. Cuanto mayor sea el número, mejor.',
            link: '¿Cómo aumentar el CTR?',
          },
        },
        none: [
          'Las campañas están llegando pronto',
        ],
      },
      widget: {
        header: {
          title: 'Configuraciones',
          widget: 'Widget',
          chatbot: 'Chatbot',
          extension: 'Extensión',
          stream: 'Transmisión en vivo',
          disabled: 'OFF',
          enabled: 'ON',
        },
        widgetPlatform: {
          title: 'Plataforma de monetización',
          description: 'Elija la plataforma donde trabajará con patrocinadores, recopilará estadísticas y monetizará. La elección de una plataforma afectará a las campañas y módulos de widget disponibles.',
        },
        widgetLink: {
          title: 'Enlace de widget',
          description: 'Enlace para OBS u otros programas de transmisión.',
          optionDrag: {
            label: 'Opción 1: arrastrar y soltar en OBS',
            helpWithSetup: 'Guía paso a paso',
            warn: 'Arrastrar y soltar en OBS no funcionará si OBS se ejecuta como administrador.',
            btn: 'Arrastrar y soltar en OBS',
          },
          optionLink: {
            label: 'Opción 2: configurar el widget desde cero',
            helpWithSetup: 'Guía paso a paso',
            warn: 'Asegúrese de que el widget esté configurado en toda el área visible en su software de transmisión y en la capa superior, y que esté seleccionada la configuración correcta. Consulta la guía.',
          },
          preview: 'Mostrar animación de prueba en software de transmisión.',
        },
        widgetDelay: {
          title: 'Retraso de transmisión',
          new: 'nuevo',
          description: 'Establecer el tiempo de retraso de transmisión para el correcto funcionamiento del servicio y las notificaciones del chatbot.',
          howItWorks: 'Cómo funciona',
          fieldLabel: 'Tiempo de retraso (0 a 1800 s)',
        },
        chatbot: {
          title: 'Configuración del chatbot',
          description: 'El chatbot se utiliza para enviar mensajes y encuestas.',
          surveyDescription: '¿No puedes configurar Nightbot o estás trabajando con otro bot?',
          wantAnotherChatbot: 'Quiero otro chatbot',
          shareChatbotSetup: 'Comparte con nosotros tu experiencia sobre la configuración del chatbot',
          goThroughTheSurvey: 'Realiza la encuesta',
          helpWithSetup: 'Guía paso a paso',
          connectNightbot: 'Conectar Nightbot',
          disconnectNightbot: 'Desactivar Nightbot',
          checkChatbot: 'Revisar Nightbot',
          sendMessage: 'Vista previa del mensaje',
          messageSent: 'Mensaje enviado',
          error: 'Antes de conectar el bot, visite el sitio web de nightbot <a href="https://nightbot.tv/dashboard" target="_blank">y finalice la sesión de juego.</a>',
        },
        advertSettings: {
          title: 'Administrador de campañas',
          description: 'Seleccione la frecuencia y el modo de colocación.',
          moreAboutFormats: 'Aprenda más sobre formatos',
          bannerTimeout: 'Frecuencia',
          adPlaybackMode: 'Modo de colocación',
          playbackModeManual: 'Manual',
          playbackModeAuto: 'Automático',
          manualPlaybackTitle: 'Lanzamiento de campaña',
          pictureInPicturePosition: 'Ubicación de Picture-in-Picture',
          leaderboardPosition: 'Ubicación de banner ancho',
          adsBlocksCountTitle: 'Número de colocación',
          adsBlocksCountDuration: 'Duración: {seconds} segundos',
          advice: {
            title: 'Lanzamiento de campañas manuales',
            firstString: 'Puedes ejecutar campañas manualmente cuando el widget, la extensión y el chatbot estén activos.',
            secondString: 'Tan pronto como la campaña esté disponible, el botón se desbloqueará.',
          },
        },
        panel: {
          title: 'Administrador de campañas remotas',
          mobileVersion: {
            title: 'Administrador de campañas móviles',
            description: 'Escanee el código QR para abrir el Administrador de campañas en su teléfono. No muestre este código a nadie.',
          },
          obsVersion: {
            title: 'Administrador de campañas para OBS',
            description: 'El Administrador de campañas se puede agregar a OBS. Copie el enlace y péguelo debajo de Ver → Docks → Docks de navegador personalizado. No muestre este enlace a nadie.',
          },
        },
        ignoreCategories: {
          title: 'Categorías para ignorar',
          description: 'Las campañas de las categorías seleccionadas se desactivarán automáticamente para su visualización en la sección de campañas.',
        },
        attention: {
          title: 'Configuración de Widgets y Chatbot',
          widgetDisabled: {
            title: 'Widget deshabilitado',
            reasons: {
              title: 'Las siguientes razones son posibles:',
              broadcast: 'El programa de difusión está deshabilitado',
              notSetUp: 'El widget no está configurado en el programa de transmisión.',
            },
          },
          chatbotDisabled: {
            title: 'Chatbot deshabilitado',
            reasons: {
              toBeConnected: 'El chatbot debe conectarse para el canal',
              addChatbotAsModerator: 'Necesita agregar a Nightbot como moderador',
            },
          },
        },
        twitchExtension: {
          title: 'Extensión de Twitch',
          description: 'Configure una extensión para Twitch para obtener campañas de patrocinio especiales en formato de extensión.',
          status: 'Estado de extensión',
          btns: {
            setup: 'Configurar extensión',
            check: 'Verificar la extensión',
          },
        },
      },
      wallet: {
        header: {
          title: 'Billetera',
        },
        tabs: {
          overview: 'Visión general',
          history: 'Historial de pagos',
        },
        balance: {
          heading: 'Sus ingresos',
          title: 'Saldo',
          currentBalance: {
            label: 'Saldo',
            popover: 'Monto de ingresos verificado',
          },
          minimumPayout: 'Cantidad mínima de pago',
          payoutAmount: 'Cantidad a pagar',
          nearestDatePayout: 'Próxima fecha de pago',
          howPayoutsWork: '¿Cómo funcionan los pagos?',
          referralInfo: '¡Felicidades! Has ganado un bono de referido de {sum}. <br/><br/>Tenga en cuenta que para mantener este bono, los términos del programa requieren que alcance un determinado número de vistas para {date}. <br/><br/>Vistas: {imps} / {impsTotal}.',
          estimatedEarnings: {
            label: 'Ingresos estimados para&nbsp;{month}',
            popover: 'Los ingresos se&nbsp;ajustar&aacute;n tras comprobar posibles fraudes',
          },
          auditedEarnings: {
            label: 'Monto en revisión',
            popover: 'Monto a verificar',
          },
          nextPayout: 'Importe a pagar el {date}',
        },
        paymentServices: {
          all: {
            title: 'Servicios de pago',
            loading: 'cargando',
            active: 'Activo para pagos',
            activate: 'Método predeterminado',
            learnMore: 'Aprende más sobre las comisiones',
            paypal: 'PayPal',
            wireTransfer: 'Transferencia bancaria',
            paypalData: 'Datos de facturación',
            billingsData: 'Datos de facturación',
            whyDoWeNeedThisData: '¿Por qué necesitamos estos datos?',
            setUp: 'Configurar el pago',
          },
          razorPay: {
            title: 'Incorporación de pagos',
            loading: 'Cargando',
            learnMore: 'Aprende más acerca de las comisiones',
            setUp: 'Configurar pago',
            statuses: {
              yourStatus: 'Tu estado',
              paymentMethod: 'Método de pago',
              payable: 'Pagadero',
              notPayable: 'No pagadero',
              notAdd: 'No agregar',
            },
            form: {
              title: 'Datos de facturación',
              whyDoWeNeedThisData: '¿Por qué necesitamos estos datos?',
            },
          },
          tochkaBank: {
            title: 'Servicios de pago',
            loading: 'Cargando',
            learnMore: 'Aprende más sobre comisiones',
            setUp: 'Configurar pago',
            employment: {
              type: 'Tipo de empleo',
              physical: 'Individual',
              physicalShort: 'Individual',
              selfEmployed: 'Autónomo',
              taxHintSelf: 'Los autónomos están obligados a pagar impuestos sobre la renta en la cantidad del 6% de la cantidad de la remuneración. El autónomo paga el impuesto por sí mismo sobre un recibo de la oficina de impuestos.',
              taxHintPhysical: 'Los individuos están obligados a pagar impuestos sobre la renta en la cantidad del 13% de la cantidad de la remuneración. Nosotros transferiremos esta cantidad al impuesto por nosotros mismos.',
              selfRegisterHint: 'Para comenzar, regístrese en el servicio "Я - сам". De esta manera, haremos automáticamente los pagos y emitiremos las facturas necesarias.',
              status: 'Estado',
              registered: 'Registrado',
              notRegistered: 'No registrado',
              register: 'Registrarse',
              formHint: '',
            },
            statuses: {
              yourStatus: 'Su estado',
              paymentMethod: 'Método de pago',
              payable: 'Pagable',
              notPayable: 'No pagable',
              notAdd: 'No agregar',
            },
            form: {
              title: 'Vinculación de cuentas',
              whyDoWeNeedThisData: '¿Por qué necesitamos estos datos?',
            },
            paymentStatus: {
              lastTransaction: 'Ultimo pago',
              status: {
                inProgress: 'En curso',
                error: 'Error',
                success: 'Éxito',
              },
              fields: {
                amount: 'Cantidad',
                date: 'Fecha',
                status: 'Estado',
                methods: 'Métodos de pago',
              },
            },
          },
        },
        analytics: {
          heading: 'Análisis de ingresos',
          categories: {
            awareness: 'Formatos de patrocinio',
            performance: 'Formatos interactivos',
            actions: 'Bono de acción',
            freemium: 'Uplify Link',
            referrals: 'Referencias',
            youtube_text: 'Youtube Text',
          },
          source: {
            title: 'Fuente de ingresos',
          },
        },
        payoutHistory: {
          title: 'Historial de pagos',
          columns: {
            date: 'Fecha',
            amount: 'Cantidad',
            service: 'Método de pago',
            invoice: 'Factura',
          },
          loading: 'cargando',
          noData: 'Aún no se han realizado pagos.',
        },
        tipaltiAdvice: {
          title: 'Gana primero {amount} para desbloquear los retiros',
          description: 'La configuración de pago solo está disponible para los creadores que hayan ganado el 80% del mínimo de pago.',
        },
        tochkaAdvice: {
          title: 'Conecte uno de los servicios de pago',
          description: 'No tienes ningún servicio de pago conectado. Conecta una forma preferida de recibir pagos.',
        },
      },
      profile: {
        header: {
          title: 'Configuración de la cuenta',
        },
        tier: {
          whatIsTier: '¿Qué es un nivel?',
        },
        agency: {
          title: 'Agencia',
        },
        form: {
          howToChangeLanguage: 'Para cambiar el idioma de transmisión y el país, envíenos un mensaje en el chat o en Discord.',
        },
        advice: {
          title: 'Verificación de correo electrónico requerida',
          info: 'Solo te informamos sobre las noticias y actualizaciones más importantes.',
          noSpam: 'Sin spam.',
          clickLink: 'Haga clic en el enlace del correo electrónico de confirmación que le enviamos.',
          link: 'Enviar de nuevo',
          linkClicked: 'Correo enviado',
        },
        platforms: {
          title: 'Plataformas vinculadas',
          writeUsToDetach: 'Escribanos para desvincular',
        },
        discord: {
          title: 'Únete a nuestro servidor de Discord para mantenerte al día, obtener ayuda y subir de nivel.',
          status: {
            caption: 'Tu estado',
            connected: 'Conectado',
            notConnected: 'No conectado',
          },
        },
        deactivation: {
          title: 'Desactivación de cuenta',
          textBlock1: 'Si quieres tomar un descanso de Uplify, puedes desactivar temporalmente tu cuenta en lugar de eliminarla. Tu perfil no aparecerá en Uplify mientras estés ausente.',
          textBlock2: 'Si continúas, tu perfil y detalles de cuenta se eliminarán el <strong>{date}</strong>. No serás visible en Uplify entre ahora y entonces. Si cambias de opinión, puedes iniciar sesión antes de la fecha de eliminación y optar por mantener tu cuenta.',
          deactivateBtn: 'Desactivar',
          drawer: {
            title: 'Desactivar cuenta',
            subtitle: '¿Estás seguro de que quieres desactivar tu cuenta?',
            textBlock1: 'Cuando se desactiva una cuenta de Uplify, suceden lo siguiente con los datos de la cuenta:',
            textBlock2: [
              'El perfil, la página pública y el widget se ocultan de la plataforma y ya no serán visibles para otros usuarios.',
              'Cualquier tipo de campañas ya no estará disponible.',
              'El saldo estará disponible hasta que se elimine la cuenta y solo se puede pagar cuando se alcanza el umbral mínimo.',
              'Uplify conservará los datos de la cuenta mientras esté desactivada. El propietario de la cuenta puede reactivarla en&nbsp;en cualquier momento durante este período.',
              'Si&nbsp;la cuenta&nbsp;no se reactiva dentro de un&nbsp;cierto período de tiempo, Uplify puede eliminar los datos de la cuenta de forma permanente. Uplify afirma que este proceso puede tardar&nbsp;hasta&nbsp;40&nbsp;días.',
              'Uplify también establece que pueden retener cierta información de cuentas desactivadas si&nbsp;es&nbsp;es&nbsp;necesario para&nbsp;cumplir con obligaciones legales, resolver disputas, prevenir fraudes o&nbsp;hacer cumplir sus políticas.',
            ],
            textBlock3: 'Es importante&nbsp;tener en cuenta que desactivar una&nbsp;cuenta no es&nbsp;lo mismo que&nbsp;eliminar una&nbsp;cuenta, y es&rsquo;un&nbsp;proceso reversible, donde la cuenta y sus datos pueden ser&nbsp;restaurados.',
            confirmBtn: 'Confirmar',
            cancelBtn: 'Cancelar',
          },
        },
      },
      referrals: {
        header: {
          title: 'Programa de referidos',
        },
        unavailable: 'Lo sentimos, el programa de referidos no está disponible para ti o para tu región. <br>Escribe a nosotros en <a href="https://discord.gg/uplify" target="_blank">Discord</a> o en el chat del sitio web si tienes alguna pregunta.',
        invite: {
          title: 'Invita a tus amigos',
          description: 'Recibe {referrer} por cada amigo que invites, y tu amigo recibirá {referral} después de completar el registro y mostrar los primeros anuncios.',
          invited: 'Invitar',
          reward: 'Cantidad para pagar',
          link: 'Enlace de invitación',
          advice: {
            title: '¿Cómo me pagan?',
            description: 'Recibirás un pago cuando un amigo se registre en Uplify, confirme el correo electrónico y muestre campañas para 5,000 vistas',
            link: {
              label: 'Términos del programa de referir a un amigo',
              url: '/',
            },
          },
        },
        promotion: {
          title: 'Promoción en stream',
          switchLabel: 'Promociona Uplify en tu stream.',
          advice: {
            title: 'Cómo funciona:',
            description: 'Puedes promocionar nuestro servicio en tu stream. Recibirás {referrer} y tus referidos recibirán {referral} después de completar todos los términos. <br/><br/>Cada dos horas, Uplify enviará un banner personalizado durante la transmisión en vivo, instando a unirse a nuestro servicio.',
          },
          link: {
            label: 'Lea más sobre la promoción',
            url: '/',
          },
          demo: {
            title: 'Haga clic para ver la vista previa de un banner en el software de transmisión.',
          },
        },
        panels: {
          title: 'Paneles de Twitch',
          description: 'Coloque un banner debajo de su reproductor para invitar a más usuarios.',
        },
        history: {
          title: 'Historial de invitaciones',
          none: 'Aún no has invitado a nadie :(',
          columns: {
            creator: 'Referido',
            date: 'Fecha de unión',
            status: 'Estado',
            impressions: 'Vistas publicitarias',
          },
          paid: 'Pagado',
          waiting: 'Esperando',
        },
        updated: {
          invite: {
            title: 'Invita a tus amigos',
            description: 'Recibe {referrer} por cada amigo que invites, y tu amigo recibirá {referral} después de completar el registro y mostrar los primeros anuncios.',
            invited: 'Invitar',
            reward: 'Cantidad para pagar',
            link: 'Enlace de invitación',
            advice: {
              title: '¿Cómo me pagan?',
              description: 'Puede promocionar UPLIFY en su transmisión en vivo.Ganará el 5% de los ingresos de todos los cretors que se refiere. <br/><br/>Cada dos horas, Uplify enviará un banner personalizado durante la transmisión en vivo, instando a unirse a nuestro servicio.',
              link: {
                label: 'Términos del programa de referir a un amigo',
                url: '/',
              },
            },
          },
          promotion: {
            title: 'Promoción en stream',
            switchLabel: 'Promociona Uplify en tu stream.',
            advice: {
              title: 'Cómo funciona:',
              description: 'Puedes promocionar nuestro servicio en tu stream. Recibirás {referrer} y tus referidos recibirán {referral} después de completar todos los términos.',
            },
            link: {
              label: 'Lea más sobre la promoción',
              url: '/',
            },
            demo: {
              title: 'Haga clic para ver la vista previa de un banner en el software de transmisión.',
            },
          },
          history: {
            advice: {
              title: 'Es importante saber',
              description: '<strong>Invitado</strong>: el&nbsp;usuario se&nbsp;ha&nbsp;registrado pero no&nbsp;ha&nbsp;completado la&nbsp;incorporaci&oacute;n.<br/><br/><strong>Incorporado</strong>: el&nbsp;usuario ha&nbsp;completado la&nbsp;incorporaci&oacute;n y&nbsp;est&aacute; listo para mostrar campa&ntilde;as patrocinadas.',
            },
            invited: 'Invitado',
            hasImpressions: 'Incorporado',
          },
        },
      },
      referralCheck: {
        title: 'Tu enlace de referencia funciona genial',
        text: 'Tus amigos pueden registrarse y obtener un bono. <br><a href="https://discord.gg/uplify" target="_blank">Contáctenos</a> si tiene alguna pregunta.',
        btnText: 'Volver a Inicio',
      },
      extension: {
        header: {
          title: 'Extensión de Twitch',
        },
        about: {
          title: 'Acerca de',
          description: 'Obtenga todos los enlaces a su contenido en un solo lugar. Con los bloques, puede vincularse a sus tiendas, especiales y redes sociales. Cree su propio diseño de bloque único, agregue enlaces y ya está.',
          status: 'Estado de la extensión',
          statusTrue: 'Todo el trabajo',
          statusFlase: 'No trabajo',
        },
        settings: {
          title: 'Ajustes',
          description: 'Crea tu propio diseño único de bloque y agrégalos al panel.',
          form: {
            block: {
              title: 'Bloque',
              type: {
                label: 'Tipo de bloque',
                placeholder: 'Selecciona el tipo de bloque',
              },
              link: {
                label: 'Ir al enlace',
                placeholder: 'Pegar enlace',
              },
              deleteBlock: 'Eliminar bloque',
            },
            addBlock: 'Añadir un bloque más',
          },
        },
        preview: {
          label: 'Vista previa del panel',
          title: 'Mi árbol de enlaces',
          action: 'Toca para avanzar',
        },
      },
      freemium: {
        title: 'Uplify Link',
        tabs: {
          setup: 'Configuración de widget',
          profile: 'Perfil',
          alerts: 'Alertas de transmisión',
          supporters: 'Analítica',
          posts: 'Publicaciónes',
        },
        posts: {
          addNewBtn: 'Agregar nueva publicación',
          post: {
            createdAt: 'Publicado en',
            edit: 'Editar',
            remove: 'Eliminar',
          },
          sidebar: {
            title: {
              add: 'Agregar nueva publicación',
              edit: 'Publicación de edición',
            },
            markdown: {
              label: 'Texto de markdown',
              hint: 'Por ejemplo:<br><br>Me encanta **<b>texto en negrita</b>**.<br>También *<i>texto en cursiva</i>* es genial.<br><br>Mi enlace favorito es [Uplify.link](https://uplify.link).',
            },
            embed: {
              label: 'Enlace de incrustación',
              hint: 'Some text',
            },
            btns: {
              post: 'Publicar',
              submit: 'Ahorrar',
            },
          },
        },
        setup: {
          link: {
            title: 'Tu página de enlace Uplify',
            description: 'Agrega este enlace a tus biografías de redes sociales y compártelo con tu comunidad. Gana más compartiendo tu enlace con regularidad.',
            errors: {
              unique: 'El creador con este nombre ya existe',
              general: 'Nombre inválido',
            },
          },
          goal: {
            title: 'Establecer una meta',
            description: 'Las metas tienden a atraer más partidarios. Agrega una meta y déjalos ser parte de tu viaje creativo.',
            addGoal: 'Agregar meta',
            reachedOf: '100 de 2 000 alcanzados!',
          },
          goalForm: {
            title: 'Crea un nuevo objetivo',
            editTitle: 'Editar objetivo',
            fields: {
              title: {
                caption: 'Título del objetivo',
                placeholder: 'p.ej. Nueva webcam',
              },
              description: {
                caption: 'Descripción del objetivo',
                placeholder: 'Cuando alcance los 200 puntos, compraré una nueva webcam para darle un nuevo nivel a mis transmisiones en vivo.',
                hint: 'Proporciona una breve descripción de las razones por las que necesitas alcanzar tu objetivo.',
              },
              amount: {
                caption: 'Cantidad objetivo',
                hint: 'Establezca una cantidad sin el signo de moneda, por ejemplo, 100. El progreso de la meta se mostrará como un porcentaje. ',
              },
              progress: {
                caption: 'Progreso hasta ahora',
                hint: 'Ingrese la cantidad que ya ha recaudado para esta meta. Ingrese 0 si está comenzando desde cero.',
              },
              publicAmount: {
                caption: 'Mostrar cantidad objetivo públicamente',
                hint: 'Mostrar la cantidad objetiva total en su página personal',
              },
            },
            statusMessage: {
              success: 'Meta fue actualizada',
              fail: 'Error al actualizar la meta',
            },
          },
          poll: {
            title: 'Establecer una nueva encuesta',
            description: 'Las encuestas ayudan a mejorar la participación de su audiencia, lo que aumentará simultáneamente su tasa de participación y sus ganancias. Los espectadores deben ver los anuncios antes de votar y obtendrá ingresos de los anuncios que muestre.',
            addPoll: 'Añadir encuesta',
            duration: 'Duración',
            votes: 'Votos - {num} restantes',
          },
          pollForm: {
            title: 'Crear una nueva encuesta',
            editTitle: 'Editar encuesta',
            responsesTitle: 'Respuestas',
            responsesTitleMin: '(mínimo 2)',
            fields: {
              question: {
                caption: 'Pregunta',
                placeholder: 'ej. ¿Qué juego debería jugar a continuación?',
              },
              answer: {
                caption: 'Respuesta',
              },
              condition: {
                caption: 'Modo de encuesta',
                hint: 'Condición de terminación: por tiempo o número de votos',
                duration: 'Minutos',
                maxVotes: 'Votos',
              },
              duration: {
                caption: 'Duración en minutos',
                hint: 'El tiempo de validez de su encuesta.',
              },
              maxVotes: {
                caption: 'Objetivo de votos',
                hint: 'La encuesta finalizará cuando alcance este número de votos',
              },
            },
            statusMessage: {
              success: 'La encuesta fue actualizada',
              fail: 'Error al actualizar la encuesta',
            },
          },
        },
        profile: {
          telegram: {
            title: 'Repositorio automático',
            description: 'Conecta tu&nbsp;red social y&nbsp;las publicaciones aparecer&aacute;n autom&aacute;ticamente en&nbsp;tu&nbsp;p&aacute;gina de&nbsp;Uplify Link.',
            input: {
              title: 'Telegram channel',
              description: 'A&ntilde;ade un&nbsp;enlace a&nbsp;tu&nbsp;canal con @ y&nbsp;asigna a&nbsp;nuestro bot @UplifyBot como administrador.',
              label: 'Link',
              placeholder: '@my_channel',
            },
            errors: {
              channelExists: 'No hay canal con este nombre',
              invalidName: 'El nombre del canal incorrecto',
            },
          },
          about: {
            title: 'Tu biografía',
            description: 'Añade datos para tu perfil',
            placeholder: 'Solo unas palabras',
            preview: 'Avance:',
            advice: {
              label: 'Sugerencia',
              title: 'Puedes usar Markdown',
              description: 'Por ejemplo:<br><br>Me encanta **<b>texto en negrita</b>**.<br>También *<i>texto en cursiva</i>* es genial.<br><br>Mi enlace favorito es [Uplify.link](https://uplify.link).',
            },
            ai: {
              label: 'Texto generado por IA para tu perfil',
              delete: 'Borrar',
              edit: 'Editar',
              like: 'Como',
              suggestionFull: 'Esto es lo que piensa nuestra IA sobre {name}',
              suggestionEmptyMale: '{name} no escribió nada sobre sí mismo, pero esto es lo que nuestra IA puede decir',
              suggestionEmptyFemale: '{name} no escribió nada sobre sí misma, pero esto es lo que nuestra IA puede decir',
            },
          },
          gear: {
            title: 'Engranajes y configuración',
            description: 'Dile a tu audiencia qué tipo de equipo de transmisión usas',
            form: {
              addBlock: 'Añadir bloque',
              deleteBlock: 'Eliminar bloque',
              block: {
                title: 'Equipo',
                category: {
                  label: 'Categoría',
                  placeholder: 'Especifica una categoría',
                },
                description: {
                  label: 'Descripción',
                  placeholder: 'Describe el equipo',
                },
                link: {
                  label: 'Enlace',
                  placeholder: 'Proporcionar un enlace',
                },
                sku: {
                  label: 'SKU',
                  placeholder: '',
                },
              },
            },
            advice: {
              label: 'Sugerencia',
              title: 'Widget de Yandex.Market',
              text: 'Consulte la&nbsp;disponibilidad de&nbsp;productos en&nbsp;Yandex.Market. Si&nbsp;falta al&nbsp;menos uno, se&nbsp;mostrar&aacute; un&nbsp;widget con ofertas similares en&nbsp;lugar de&nbsp;tus productos.',
            },
          },
          social: {
            title: 'Enlaces a redes sociales',
            description: 'Comparte enlaces de redes sociales con tu audiencia',
            form: {
              addBlock: 'Añadir bloque',
              deleteBlock: 'Eliminar bloque',
              block: {
                title: 'Enlace',
                category: {
                  label: 'Red social',
                  placeholder: 'Seleccionar red social',
                },
                description: {
                  label: 'URL',
                  placeholder: 'Pegue el enlace',
                },
              },
            },
          },
          theme: {
            title: 'Tema de color',
            description: 'Elija un tema de color para su página Uplify Link',
            select: {
              label: 'Tema de color',
              options: {
                light: 'Luz',
                dark: 'Oscuro',
              },
            },
          },
          banner: {
            title: 'Banner de perfil',
            description: 'Personaliza el banner de tu perfil en la página personal',
            label: 'Sube un PNG, JPG de menos de 5 MB. El tamaño de la imagen debe ser de al menos 1376x300 px.',
          },
        },
        alerts: {
          supportAlert: 'Alerta de soporte',
          goalAlert: 'Alerta de objetivo',
          pollAlert: 'Alerta de encuesta',
          chatAlert: 'Alerta de chat',
          preview: 'Vista previa',
          alertPreview: {
            title: 'Vista previa de alerta',
            description: 'Configure su widget en su software OBS (OBS Studio, Twitch Studio o Xsplit) y pruebe alertas antes de transmisión en vivo.',
            sendTest: 'Enviar vista previa',
          },
          chatBotReminder: 'Requiere configurar un bot de chat',
          fields: {
            messageDuration: {
              caption: 'Tiempo de visualización de alerta',
              hint: 'Cuánto tiempo se mostrará una alerta en la transmisión en vivo.',
            },
            supportAlertPosition: {
              caption: 'Posición de alerta de apoyo',
              hint: 'Especifica dónde en la pantalla se mostrará la alerta.',
            },
            goalAlertPosition: {
              caption: 'Posición de alerta de objetivo',
              hint: 'Especifica dónde en la pantalla se mostrará la alerta.',
            },
            pollAlertPosition: {
              caption: 'Posición de alerta de encuesta',
              hint: 'Especifica dónde en la pantalla se mostrará la alerta.',
            },
            chatAlert: {
              caption: 'Habilitar alertas de chat',
              hint: 'Especifica si se muestran o no notificaciones interactivas durante una transmisión en vivo.',
            },
          },
          statusMessage: {
            success: 'Configuraciones actualizadas',
            fail: 'Error al actualizar configuraciones',
          },
        },
        supporters: {
          stats: {
            supporters: 'Ingresos estimados hoy',
            revenueDay: 'Ingresos estimados hoy',
            cpm: 'Precio medio por 1.000 visitas (CPM)',
            points: 'Puntos mensuales',
            revenueMonth: 'Ingresos estimados mensuales',
            impressions: 'Impresiones entregadas',
          },
          history: {
            supporters: 'Soportadores',
            supportedYou: '¡te han apoyado!',
            noSupportersTitle: 'Todavía no tienes soportadores',
            noSupportersMessage: 'Anima a tus oyentes a visitar tu página para obtener sus primeros soportes',
            impressions: '{imps} vistas de anuncios',
            targets: {
              today: 'Hoy',
              yesterday: 'Ayer',
              week: 'Esta semana',
              month: 'Este mes',
              allTime: 'Todo el tiempo',
            },
            columns: {
              id: '#',
              name: 'Nombre',
              contribution: 'Puntos',
            },
            cacheTimeLeft: 'Esta calificación se actualizará en {min} min.',
          },
        },
        widgetPosition: {
          leftTop: 'Esquina superior izquierda',
          rightTop: 'Esquina superior derecha',
          leftBottom: 'Esquina inferior izquierda',
          rightBottom: 'Esquina inferior derecha',
          disabled: 'OFF',
        },
        chatbotMsgInterval: {
          every15mins: 'Cada 15 minutos',
          every30mins: 'Cada 30 minutos',
          everyHour: 'Cada 1 hora',
          disabled: 'OFF',
        },
      },
      deactivated: {
        title: 'Tu cuenta está desactivada',
        headline: 'Tu perfil y detalles de cuenta se ocultarán hasta <strong>{date}</strong> y se eliminarán en esa fecha.',
        textBlock1: 'Tu cuenta de Uplify está desactivada, ¿qué significa esto?',
        textBlock2: [
          'El perfil, la página pública y el widget están ocultos de la plataforma y ya no serán visibles para otros usuarios.',
          'No estarán disponibles ningún tipo de campañas.',
          'El saldo estará disponible hasta que se borre la cuenta y sólo se pagará cuando se alcance el umbral mínimo.',
          'Uplify mantendrá los datos de la cuenta mientras esté desactivada. El propietario de la cuenta puede reactivarla en cualquier momento durante este período.',
          'Si la cuenta no se reactiva dentro de un cierto período de tiempo, Uplify puede borrar los datos de la cuenta de forma permanente. Uplify indica que este proceso puede tardar hasta 40 días.',
          'Uplify también afirma que pueden retener cierta información de cuentas desactivadas si es necesario cumplir con obligaciones legales, resolver disputas, prevenir el fraude o hacer cumplir sus políticas.',
        ],
        revokeBtn: 'Revocar',
      },
    },
    partner: {
      campaigns: {
        header: {
          title: {
            'brand-awareness': 'Campañas de patrocinio',
            performance: 'Campañas interactivas',
            preroll: 'Campañas preroll',
          },
        },
        balance: 'Balance',
        tabs: {
          'brand-awareness': 'Conciencia',
          performance: 'Rendimiento',
          preroll: 'Preroll',
        },
        types: {
          campaign: 'Campañas',
          group: 'Grupos',
          creative: 'Creativos',
        },
        tables: {
          actions: {
            downloadReport: 'Descargar Informe',
            viewReport: 'Ver informe',
            duplicate: 'Duplicar',
            remove: 'Eliminar',
          },
          columns: {
            name: 'Nombre',
            state: 'Estado',
            disabledStatusReason: {
              closed: 'Contacte al soporte para activar',
              parentIsDisabled: 'Padre deshabilitado',
            },
            clicks: 'Clics',
            ctr: 'CTR',
            impressions: 'Vistas',
            reach: 'Alcance',
            id: 'ID',
            relatedCampaign: 'Campaña Relacionada',
            relatedGroup: 'Grupo Relacionado',
            format: 'Formato',
            budget: 'Presupuesto',
            budgetUsed: 'Presupuesto Utilizado',
            totalBudget: 'Presupuesto Total',
            advertiser: 'Anunciante',
            startedAt: 'Iniciado En',
            endedAt: 'Terminado En',
            platform: 'Plataforma',
            moderation: 'Moderación',
            moderationStatus: {
              pending: 'Pendiente',
              complete: 'Aprobado',
            },
            filterLabel: 'Ajustes de columna',
            channels: 'Canales',
            daysRemaining: 'Días restantes',
            externalId: 'ID externo',
            bid_cap: 'Bid cap',
            bid_cpa: 'Bid CPA',
            actions: 'Acciones',
          },
          noCampaigns: 'No tienes campañas',
          noGroups: 'No tienes grupos',
          noCreatives: 'No tienes creativos',
        },
        newCreative: {
          title: 'Nueva creatividad',
          description: 'Seleccione el grupo al que pertenecerá la creatividad',
        },
        newGroup: {
          title: 'Nuevo grupo',
          description: 'Seleccione la campaña a la que pertenecerá el grupo',
        },
      },
      balance: {
        header: {
          title: 'Balance',
        },
        info: {
          title: 'Balance Actual',
          howFundUpBalance: '¿Cómo recargar saldo?',
        },
        history: {
          title: 'Historial de pagos',
          table: {
            columns: {
              date: 'Fecha',
              amount: 'Cantidad',
              service: 'Servicio',
            },
          },
          noPayments: 'No hay pagos todavía',
        },
      },
      creators: {
        header: {
          title: 'Agencia',
        },
        tabs: {
          overview: 'Descripción general',
          history: 'Historial de transacciones',
          campaignsActive: 'Campañas',
          campaignsClosed: 'Completado',
          creators: 'Creadores',
        },
        invite: {
          title: 'Invitar creadores',
          invited: 'Creadores invitados',
          amount: 'Cantidad a ser pagada',
          link: 'Enlace de invitación',
          advice: {
            title: 'Obtén una parte de los ingresos',
            description: 'Genera una participación de los ingresos de los creadores que refieras mientras estén activos en Uplify.',
            link: {
              url: '/',
              label: 'Cómo funciona',
            },
          },
        },
        creatorsList: 'Lista de creadores',
        history: {
          title: 'Historial de transacciones',
          payments: 'Transacciones de todos los tiempos',
          none: 'No hay transacciones',
          columns: {
            date: 'Fecha',
            payment: 'Pago por día',
            details: 'Detalles',
          },
          detailed: {
            title: 'Detalles de transacciones',
            description: 'Creadores que trajeron dinero ese día.',
          },
        },
        list: {
          title: 'Lista de creadores',
          description: 'Lista de creadores que invitaste. Pagos por todo el tiempo ',
          none: 'No hay creadores',
          columns: {
            creator: 'Creador',
            lastActivity: 'Último día de actividad',
            earnings: 'Ganancias',
          },
        },
        creatorsPayout: {
          title: 'Pago de los creadores',
          description: 'Ingrese la cantidad que sus creadores deberían recibir cuando patrocinen su campaña. Especifique el coste por cada mil vistas (CPM).',
          link: {
            text: 'Más sobre formatos creativos',
            href: 'https://help.uplify.app/en/articles/5640856-in-stream-campaign-formats',
          },
          thirdParty: {
            checkbox: 'Permitir que socios externos realicen patrocinios',
            popover: {
              label: 'Consejo',
              text: 'Ingrese el monto básico que incluye su comisión y el pago de los creadores. Especifique el coste por cada mil vistas (CPM).',
            },
          },
          comission: {
            label: 'Comisión',
            popover: {
              label: 'Consejo',
              text: 'Se utiliza para campañas con acuerdos individuales con patrocinadores o si los pagos a los creadores no están configurados.',
            },
          },
          darkMarket: {
            checkbox: 'Habilitar una tarifa más alta para los clientes del mercado oscuro',
            popover: {
              label: 'Consejo',
              text: 'Ingrese un monto base para clientes de la categoría del mercado oscuro (juegos de apuestas, casino, criptomonedas, etc.). Especifique el costo por cada mil vistas (CPM).',
            },
          },
          cpaLabel: 'Pago de los creadores',
          darkMarketLabel: 'Precios de terceros (incl. tarifa)',
          fields: {
            video: 'Overlay 50%',
            custom: 'Custom',
            pip: 'Overlay 15%',
            interactive: 'Interactivo',
          },
        },
        creatorsTable: {
          columns: {
            creators: 'Creador',
            lastActivity: 'Última actividad',
            balance: 'Saldo',
            campaign: 'Campañas',
          },
          status: {
            checkList: {
              label: 'Incorporación en progreso',
              text: 'El streamer aún no ha completado el proceso de incorporación.',
            },
            payable: {
              label: 'Verifique la configuración de pago',
              text: 'El streamer aún no ha configurado el pago.',
            },
            lowCtr: {
              label: 'CTR bajo',
              text: 'Problemas con el CTR en todas las campañas.',
            },
          },
          none: 'Sin datos',
        },
        settings: {
          title: 'Configuraciones',
          custom: {
            headline: 'Pago personalizado',
            descr: 'Ingrese el monto personalizado que {streamer} debe recibir al realizar el patrocinio. Especifique el coste por cada mil vistas (CPM)',
          },
          darkMarket: {
            headline: 'Tarifa para clientes del mercado oscuro',
          },
          cpaLabel: 'Pago de los creador',
          darkMarketLabel: 'Precios de terceros (incl. tarifa)',
          fields: {
            video: 'Overlay 50%',
            custom: 'Custom',
            pip: 'Overlay 15%',
            interactive: 'Interactivo',
          },
        },
        stats: {
          columns: {
            creator: 'Creador',
            campaign: 'Campaña',
            income: 'Ingreso',
            views: 'Vistas',
            avgCtr: 'Prom. CTR',
            ctr: 'CTR',
            status: 'Estado',
          },
          none: 'Sin datos',
        },
        campaignCreators: {
          title: 'Informe de campaña: {title}',
        },
        creatorCampaigns: {
          title: '{streamer} campañas',
        },
        categoriesStopList: {
          title: 'Lista de parada de categorías',
          description: 'Las campañas de las categorías seleccionadas se desactivarán automáticamente para su visualización en la sección de anuncios In-stream para sus creadores.',
        },
        campaigns: {
          active: {
            title: 'Campañas activas',
            description: 'Lista de campaña disponible para sus creadores',
          },
          closed: {
            title: 'Campañas cerradas',
            description: 'Lista de campañas cerradas',
          },
          reportBtn: {
            full: 'Informe de campaña',
            short: 'Informe',
          },
        },
      },
      campaign: {
        defaultName: 'Nombre de la campaña',
        defaultNameNew: 'Nueva campaña',
        group: 'Grupo',
        loading: 'Cargando campaña ..',
        type: {
          title: 'Tipo de campaña',
          description: 'Elige el tipo de campaña que mejor se adapte a tus objetivos de marketing.',
          comingSoon: 'Próximamente',
        },
        types: {
          awareness: {
            title: 'Awareness de marca',
            description: 'Coste fijo de anuncios basado en el modelo CPM',
          },
          brandLift: {
            title: 'Brand Lift',
            description: 'Investigación que mide el nivel de percepción de marca o producto',
          },
          task: {
            title: 'Tarea',
            description: 'Asignación con un coste individual y participación directa del streamer',
          },
          performance: {
            title: 'Rendimiento',
            description: 'Costo de publicidad flexible basado en el modelo CPA',
          },
        },
        settings: {
          title: 'Ajustes de la campaña',
          advice: {
            title: '¿Qué es esta información?',
            name: {
              title: 'Nombre de la campaña',
              description: 'que aparecerá de forma diferente durante la transmisión a los transmisores.',
            },
            currency: {
              title: 'La moneda',
              description: 'en la que se cobrará la colocación de su campaña.',
            },
          },
          form: {
            name: {
              label: 'Nombre de la campaña',
              placeholder: 'Por ejemplo, promoción de primavera',
            },
            externalId: {
              label: 'ID externo',
              placeholder: 'Por ejemplo, PF-CMP-1661693993',
            },
            description: {
              label: 'Descripción de la campaña',
              placeholder: 'Describa la campaña',
            },
            category: {
              label: 'Categoría de la campaña',
              placeholder: 'Seleccione la categoría',
              noData: 'Sin categorías',
            },
            currency: {
              label: 'Moneda',
              placeholder: 'Seleccione la moneda',
            },
            schedule: {
              startedAtLabel: 'Fecha de inicio',
              endedAtLabel: 'Fecha de finalización',
            },
            holding: {
              label: 'Anunciante holding',
              placeholder: 'Seleccione holding',
            },
            advertiser: {
              label: 'Anunciante',
              placeholder: 'Seleccione anunciante',
              noData: 'Sin datos',
            },
            status: {
              label: 'Estado',
            },
            awareness: {
              description: {
                label: 'Conciencia',
                placeholder: 'Describa la campaña para los streamers',
              },
            },
            brandlift: {
              description: {
                label: 'Impulso de marca',
                placeholder: 'Describa la campaña para los streamers',
              },
            },
            timezone: {
              label: 'Zona horaria',
              placeholder: 'Zona horaria',
            },
            formSendStatus: {
              updated: 'La campaña fue actualizada',
            },
          },
        },
        analytics: {
          title: 'Configuración de análisis',
          urlParams: {
            description: 'Parámetros adicionales para la URL que le permiten realizar un seguimiento del rendimiento de sus campañas.',
            paramBlock: 'Parámetro',
            param: 'Parámetro',
            name: 'Valor',
            addParam: 'Añadir otro parámetro',
            deleteParam: 'Eliminar parámetro',
          },
          pixel: {
            description: 'Uplify Pixel lo ayuda a rastrear la actividad del usuario en el sitio del anunciante para publicar anuncios de manera efectiva.',
            label: 'Uplify Pixel',
            btn: 'Copiar',
          },
        },
      },
      group: {
        defaultName: 'Nombre del Grupo',
        defaultNameNew: 'Nuevo Grupo',
        loading: 'Cargando Grupo ..',
        settings: {
          title: 'Configuraciones de grupo',
          description: 'Agrupe sus campañas por parámetros y establezca objetivos para rastrear cada una de ellas eficientemente',
          form: {
            name: {
              title: 'Nombre del grupo',
              label: 'El nombre solo es visible para ti',
              placeholder: 'Grupo',
            },
            description: {
              title: 'Descripción del grupo',
              label: 'Descripción del grupo',
              placeholder: 'Describe el grupo para el autor',
            },
            externalId: {
              title: 'ID externo',
              placeholder: 'Por ejemplo, PF-CMP-1661693993',
            },
            altName: {
              addBtn: 'Agregar nombre alternativo para streamers',
              label: 'Nombre del grupo para streamers',
              placeholder: 'Por ejemplo, Promoción de Primavera',
            },
            platform: {
              title: 'Plataforma',
            },
            format: {
              title: 'Formato',
              moreAbout: 'Aprende más sobre formatos creativos',
              formats: {
                fullscreen: {
                  label: 'Vídeo a pantalla completa',
                  description: 'Anuncio de media duración colocado en un stream en vivo a pantalla completa',
                },
                smallVideo: {
                  label: 'Vídeo en una ventana separada',
                  description: 'Anuncio de media duración colocado en un stream en vivo en 1/4 de pantalla',
                },
                custom: {
                  label: 'Solución personalizada',
                  description: 'Creativo multi-formato en un stream en vivo en cualquier área visible de la pantalla',
                },
              },
            },
            schedule: {
              title: 'Horario',
              startedAtLabel: 'Fecha de inicio',
              endedAtLabel: 'Fecha de finalización',
            },
            payType: {
              payPerImpression: 'Paga por impresión',
              payPerAction: 'Paga por acción',
            },
            budget: {
              title: 'Presupuesto',
              costPerUnitLabel: 'Costo de publicidad por unidad',
              impressionsLabel: 'Número de vistas',
              totalBudget: 'Presupuesto total',
              costPerActionLabel: 'Costo por acción',
              budgetLabel: 'Presupuesto',
              totalActions: 'Cantidad esperada de acciones:',
            },
            targets: {
              title: 'Objetivos',
              ctrLabel: 'CTR objetivo',
              cpaLabel: 'CPA objetivo',
            },
            frequency: {
              title: 'Frecuencia',
              standard: {
                label: 'Estándar',
                description: 'Estándar - Frecuencia recomendada de vistas con alcance óptimo: Adecuado para campañas a largo plazo',
              },
              accelerated: {
                label: 'Acelerado',
                description: 'Acelerado - Frecuencia de vistas con alcance máximo: adecuado para campañas a corto plazo',
              },
              customizable: {
                label: 'Personalizable',
                description: 'Personalizable - Frecuencia de vistas basada en parámetros de alcance específicos: adecuado para campañas no estándar',
              },
              impressionsCount: 'Número de vistas',
              period: 'Período',
            },
            status: {
              label: 'Estado',
            },
            timezone: {
              label: 'Zona horaria',
              placeholder: 'Zona horaria',
            },
            formSendStatus: {
              updated: 'El grupo fue actualizado',
            },
          },
        },
        targeting: {
          title: 'Objetivos',
          addition: {
            onlySelected: 'solo seleccionado',
            exceptSelected: 'todos, excepto seleccionado',
            excludeSelected: 'Excluir categorías seleccionadas de objetivos',
          },
          evaluation: {
            label: 'Evaluación de la audiencia',
            description: 'Seleccione un idioma de stream para obtener una estimación preliminar de la audiencia',
            size: 'Tamaño',
            reach: 'Alcance',
            streamersNumber: 'Número de transmisiones',
            howCalculated: '¿Cómo se calculan los indicadores?',
          },
          form: {
            agencies: {
              label: 'Selección de red',
              placeholder: 'Seleccionar agencia',
              warning: {
                title: 'Su valor para CPM es más bajo que el precio de la red',
                text: 'El&nbsp;valor ingresado para CPM es&nbsp;menor que el&nbsp;costo que requiere la&nbsp;red de&nbsp;socios.Podr&aacute; guardar la&nbsp;campa&ntilde;a, pero no&nbsp;funcionar&aacute; hasta que se&nbsp;corrija el&nbsp;valor.',
              },
            },
            streamers: {
              label: 'Selección de Streamers',
              placeholder: 'Seleccione streamers',
            },
            language: {
              label: 'Idioma de traducción',
              placeholder: 'Seleccione idioma',
            },
            countries: {
              label: 'Países',
              placeholder: 'Seleccione país',
            },
            gender: {
              label: 'Género',
              placeholder: 'Seleccione género',
            },
            age: {
              label: 'Edad de los Streamers',
              fromPlaceholder: 'desde',
              toPlaceholder: 'hasta',
            },
            ageRestrictions: {
              label: 'Streamer tiene restricciones de edad habilitadas',
            },
            tags: {
              label: 'Etiquetas',
              placeholder: 'Seleccione etiqueta',
            },
          },
        },
        labels: {
          title: '<strong>Etiqueta de patrocinio</strong><br/>(solo mercado CEI)',
          fields: {
            chatbot: {
              label: 'Chatbot erid ID',
            },
            creative: {
              label: 'Creative erid ID',
            },
          },
        },
        chatBot: {
          title: 'Configuración del Chatbot',
          form: {
            productUrl: {
              label: 'Enlace del producto',
              placeholder: 'Ingresar enlace',
            },
            productUrlShort: {
              label: 'URL corta',
              placeholder: '',
            },
            chatBotText: {
              label: 'Texto del Chatbot',
              placeholder: 'Ingresar texto',
            },
          },
        },
        creative: {
          title: 'Creativa de datos',
          form: {
            productUrl: {
              label: 'Enlace del producto',
              placeholder: 'Ingresar enlace',
            },
            videoDescriptionText: {
              label: 'Descripción Texto',
              placeholder: 'Ingresar texto',
            },
          },
        },
        preview: {
          title: 'Vista previa de la carga',
          requirements: 'Resolución: 1920 x 1080 píxeles.<br/>Formato: MP4, GIF<br/>Tamaño: hasta {size} MB',
          errors: {
            unknown: 'Error desconocido',
          },
        },
        messages: {
          groupUpdatedSuccesfully: 'Grupo actualizado exitosamente',
        },
        analytics: {
          title: 'Configuración de análisis',
          pixelClicks: {
            label: 'Pixel por clics',
            placeholder: '',
          },
          erid: {
            title: 'ERID (solo mercado CEI)',
            label: 'erid ID',
            placeholder: '',
          },
        },
      },
      creative: {
        defaultName: 'Nombre creativo',
        defaultNameNew: 'Nuevo creativo',
        loading: 'Creativo cargando ..',
        form: {
          name: {
            title: 'Nombre creativo',
            label: 'El nombre es visible solo para ti',
            placeholder: 'Ingrese texto',
          },
          files: {
            title: 'Subir archivos',
            formats: {
              video: 'Vídeo',
              videoOrImage: 'Vídeo o imagen',
              html5: 'HTML5',
              banner: 'Banner',
            },
            requirements: {
              title: 'Requisitos',
              fullscreen: 'Duración: 15s<br/>Resolución: 1920x1080<br/>Formato: H.264 (MP4, webm)<br/>Sonido: -9dB<br/>Tasa de bits: 2000 - 5500 kbps<br/>Velocidad de fotogramas: 24 - 30<br/>Tamaño: hasta 10 MB',
              leaderboard: 'Duración: 15s<br/>Resolución: 1920x270<br/>Formato: H.264 (MP4, webm)<br/>Sonido: Deshabilitado<br/>Tasa de bits: 2000 - 5500 kbps<br/>Velocidad de fotogramas: 24 - 30<br/>Tamaño: hasta 5 MB',
              pip_video: 'Duración: 15s<br/>Resolución: desde 550 x 310<br/>hasta 1920 x 1080 píxeles.<br/>Formato: H.264 (MP4, webm)<br/>Sonido: Deshabilitado<br/>Tasa de bits: 2000 - 5500 kbps<br/>Velocidad de fotogramas: 24 - 30<br/>Tamaño: hasta 5 MB',
              custom: 'Duración: 15s<br/>Resolución: 1920x1080<br/>Formato: PNG, JPG o GIF<br/>HTML de terceros: archivo zip HTML5<br/>Sonido: Deshabilitado<br/>Velocidad de fotogramas: 24 - 30<br/>Tamaño: hasta 10 MB',
            },
            errors: {
              general: 'Proporcione archivo creativo válido',
            },
            instructions: {
              dragImage: 'Arrastra la imagen aquí',
              dragVideo: 'Arrastra una imagen o video aquí',
              dragImageOrVideo: 'Arrastra el vídeo aquí',
              dragZip: 'Arrastre el archivo con archivos aquí',
            },
          },
          creativeManager: {
            messages: {
              deleted: 'Se eliminó el archivo adjunto',
            },
            errors: {
              unknown: 'Error desconocido',
            },
          },
          advice: {
            title: 'Vista previa creativa',
            description: 'Estime cómo se verá su anuncio',
            button: 'Mostrar vista previa',
          },
          data: {
            title: 'Creativo de datos',
            fields: {
              erid: {
                title: 'ERID (solo mercado CEI)',
                label: 'erid ID',
                placeholder: '',
              },
              productUrl: {
                label: 'Enlace a tu producto',
                placeholder: 'Introducir enlace',
              },
              chatbotText: {
                label: 'Texto del chatbot',
                placeholder: 'Introducir texto',
              },
              companionHeading: {
                label: 'Título de la bandera de compañero',
                placeholder: 'Introducir título',
              },
              companionText: {
                label: 'Texto de la bandera de compañero',
                placeholder: 'Introducir texto',
              },
              companionCta: {
                label: 'Llamada a la acción',
                placeholder: 'Introducir texto',
              },
              qrCode: {
                label: 'Generar código QR',
              },
              pixelClicks: {
                label: 'Pixel por clics',
                placeholder: 'Introducir enlace',
              },
              pixelImpressions: {
                label: 'Pixel por vista',
                placeholder: 'Ingrese el enlace',
                addLabel: 'Agrega otro rastreador',
              },
              pixelInspections: {
                label: 'Pixel para inspección',
                placeholder: 'Ingrese el enlace',
                addLabel: 'Agrega otro rastreador',
              },
            },
          },
          labels: {
            title: '<strong>Etiqueta de patrocinio</strong><br/>(solo mercado CEI)',
            fields: {
              chatbot: {
                label: 'Chatbot erid ID',
              },
              creative: {
                label: 'Creative erid ID',
              },
            },
          },
          formSendStatus: {
            updated: 'La creatividad fue actualizada',
          },
        },
      },
      profile: {
        header: {
          title: 'Perfil',
        },
        agency: {
          title: 'Agencia',
        },
        settings: {
          title: 'Acerca de ti',
        },
        password: {
          title: 'Cambiar contraseña',
        },
      },
      analytics: {
        campaignOverview: 'Resumen de la campaña',
        creators: 'Creadores',
        categories: 'Categorías',
        status: 'Estado',
        active: 'Activa',
        inactive: 'Inactiva',
        updated: 'Actualizado',
        downloadCSV: 'Descargar CSV',
        downloadExcel: 'Descargar Excel',
        impressions: 'Vistas',
        CTR: 'CTR',
        spent: 'Gastado',
        fills: 'Rellenos',
        reach: 'Alcance',
        channels: 'Canales',
        avgCPM: 'Promedio CPM',
        totalClicks: 'Clicks totales',
        clicks: 'Clics',
        adFormat: 'Formato',
        outOf: 'De',
        creator: 'Creador',
        category: 'Categoría',
        runATestCampaignToday: '¡Lanza una campaña de prueba hoy!',
        viewability: 'Visibilidad',
        overviewTab: {
          title: 'Rendimiento de Campaña',
          performanceTitle: 'Rendimiento por días',
          creativeStats: 'Estadísticas Creativas',
          allCategories: 'Todas las categorías',
          groupName: 'Nombre del Grupo',
          creativeName: 'Nombre Creativo',
          top5Channels: 'Top 5 canales',
          top5Categories: 'Top 5 categorías',
          creatorsLinkCaption: 'Ir a todos los creadores',
          categoriesLinkCaption: 'Ir a todas las categorías',
        },
        creatorsTab: {
          title: 'Rendimiento de los creadores',
        },
        categoriesTab: {
          title: 'Rendimiento de las categorías',
          otherCategories: 'Otras categorías',
        },
      },
      advertisers: {
        header: {
          title: 'Anunciantes',
        },
        tabs: {
          advertisers: 'Anunciantes',
          holdings: 'Tenencias',
        },
        advertisers: {
          table: {
            advertiser: 'Anunciante',
            currency: 'Moneda',
            balance: 'Saldo',
            links: 'Enlaces (Patrocinio)',
          },
          links: {
            campaigns: 'Campañas',
            groups: 'Grupos',
            creatives: 'Creativos',
            holdings: 'Tenencias',
          },
        },
        holdings: {
          table: {
            holding: 'Nombre de la Tenencia',
            description: 'Descripción',
          },
        },
      },
    },
    widget: {
      adForSupport: 'Promoción por',
      willStartIn: 'comenzará en',
      poweredBy: 'Potenciado por Uplify',
      of: 'de',
      reloginMessage: {
        title: '¡Atención, {plataforma} actualizada!',
        description: 'Versión del widget está desactualizada. Por favor, cierre sesión y vuelva a iniciar sesión en el panel.',
        streamer: {
          label: 'Atención',
          title: '¡{platform} actualizado!',
          description: 'Versión del widget está desactualizada. Por favor, cierra sesión e inicia sesión en el panel.',
          button: 'Vuelve a iniciar sesión en el panel',
        },
      },
      chatbotErrorMessage: {
        title: 'El chatbot no está funcionando',
        description: 'Por favor reconecta el bot en tu cuenta personal. Instrucción paso a paso: fix.uplify.app/chatbot',
      },
      resolutionErrorMessage: {
        title: 'Widget configurado incorrectamente',
        description: 'Por favor configura el widget siguiendo la guía paso a paso: <span>fix.uplify.app/widget</span>',
      },
      tooManySubscribers: {
        title: 'El widget ha sido bloqueado',
        description: 'Elimine todas las copias del widget y actualice la capa.',
      },
      platformDisclaimer: 'no patrocina ni respalda esta promoción y no es responsable de esto.',
      platformDisclaimerYoutube: 'Esta promoción es organizada por el creador, de forma independiente de YouTube.',
      freemium: {
        timeLeft: '{time} minutos restantes',
        votesLeft: 'Votos - {num} restantes',
        pollWinner: 'Ganador',
        goalQrTitle: 'Apóyame en',
        goalTopSuppoerters: {
          day: 'Los principales seguidores: Hoy',
          week: 'Los principales seguidores: Últimos 7 días',
          quarter: 'Los principales seguidores: Todo el tiempo',
          alltime: 'Los principales seguidores: Todo el tiempo',
        },
        supporterWatcherSponsorVideo: 'Vio el video de patrocinio',
        anonymous: 'Alguien',
        supportedYou: 'te apoyó',
        chatbotMsgs: [
          'Apóyame viendo los anuncios {link}',
          'Las donaciones sin dinero se inventaron aquí {link}',
          'Ahora es fácil apoyar mi transmisión: vea el enlace y vea los anuncios {link}',
          'Se aceptan donaciones, propinas y recortes. No se necesita tarjeta {link}',
          'Apóyame de nuevo, incluso si ya has hecho clic en el enlace {link}',
          'No necesitas una tarjeta bancaria cuando las donaciones son gratuitas {link}',
          'Cada clic en este enlace es tu apoyo para mi transmisión {link}',
          'Donaciones sin SMS y registro {link}',
        ],
      },
    },
    panel: {
      tabs: {
        widget: 'Configuración',
        campaigns: 'Campañas',
      },
      states: {
        title: 'Estado de los módulos',
      },
      params: {
        title: 'Opciones de visualización de la campaña',
        previewCaption: 'Previsualización de patrocinio en el programa',
        locationCaption: 'Posición en el programa',
      },
      campaigns: {
        title: 'Campañas disponibles',
        none: 'No hay campañas disponibles',
        comeBack: 'Todas las campañas han terminado hoy.<br>¡Vuelve mañana!',
      },
      error: {
        title: 'Enlace caducado',
        text: 'Visita tu panel personal para obtener un nuevo enlace',
      },
      statusesMustBeOn: 'Para ejecutar campañas, todos los módulos deben estar activados',
      logger: {
        copy: 'Copiar registro',
        copied: '¡Registro Copiado!',
      },
    },
    page404: {
      pageNotFound: 'Página no encontrada',
      back: 'Volver a la página principal',
      question: '¿Tienes alguna pregunta?',
      chat: 'Chatea con nosotros',
    },
  },
}

export default dictionary
